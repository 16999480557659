@import "./spacing.scss";
@import "./editorStyles.scss";
@import "./circularStyles.scss";
@import "./switchButtonStyles.scss";

@-ms-viewport {
  width: device-width;
}

html {
  font-size: 0.875rem;
  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
}
// html * {
//   background:rgba(240, 0, 0, 0.1);
//   outline: 1px solid aliceblue;
// }

*:focus {
  outline: 0 !important;
}

.iossc {
  -webkit-overflow-scrolling: touch;
}
.overflow-unset {
  overflow: unset !important;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-y-barless {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.overflow-y-barless::-webkit-scrollbar {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}

.mw-480 {
  max-width: 480px !important;
}
.w-mincont {
  width: min-content;
}

.mui-link-button {
  &:hover {
    color: white;
  }
}

.mui-label-center {
  width: fit-content;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.mui-label-center-shrink {
  transform: translate(0, 0) scale(0.75);
  transform-origin: top center;
}

.text-blackjack {
  font-family: BlackJack, cursive;
}
.bg-offWhite{
  background-color: #F0F3F5!important;
}
.bg-light-primary {
  background: #{$primary}1a !important;
}
.bg-fancy {
  background: $primary linear-gradient(to bottom right, $primary, #03dac6) !important;
}
.bg-body {
  background-color: $body-bg !important;
}
.bg-yellow {
  background-color: #f5cc45;
}
.bg-neutral {
  background-color: #6c757d;
}
.bg-f0f3f5 {
  background-color: #F0F3F5;
}
.text-d0d0d0 {
  color: #d0d0d0 !important;
}
.text-0d0d0d {
  color: #0D0D0D !important;
}
.text-27AE60 {
  color:#27AE60 !important;
}
.text-D73717 {
  color: #D73717 !important;
}
.text-primary {
  color: #4089f7 !important;
}
.text-65768C {
  color: #65768C !important;
}
.text-1B1B1B {
  color:#1B1B1B;
}
.text-black {
  color: #000 !important;
}
//bg of header-list / diolog-list
.bg-grey-new {
  background-color: #F2F4F7 !important;
}
.bg-light-bluegray {
  background-color: #EBEDF0 !important;
}
.bg-light-blue {
  background-color: #E8F1FC !important;
}
.text-px-blue {
  color: #468ea0;
}
.text-px-green {
  color: #65be9f;
}
.text-px-orange {
  color: #FF6238!important;
}
.text-px-yellow {
  color: #f5cc45;
}
.text-adornments{
  color:#65768C;
}
.text-labels{
  color:#65768C;
}
.w-mx100 {
  max-width: 100vw;
}
.w-mn0 {
  min-width: 0px !important;
}
a.nolink:hover {
  color: unset;
}
a.nounderline:hover {
  text-decoration: none !important;
}

.white-overlay {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;

  margin: 0;
}

@import "./mixins.scss";

.container {
  @include px-responsive-paddings(
    (
      left: 2,
      right: 2
    )
  );
}
.container.no-gutters,
.container-fluid.no-gutters {
  margin: auto;
  @include px-responsive-paddings(
    (
      left: 0,
      right: 0
    )
  );
}
.container.half-gutters,
.container-fluid.half-gutters {
  margin: auto;
  @include px-responsive-paddings(
    (
      left: 4,
      right: 4
    )
  );
}

.mt-neg-8 {
  @include px-responsive-margins(
    (
      top: -1
    )
  );
}
.mt-neg-6 {
  @include px-responsive-margins(
    (
      top: -2
    )
  );
}
.mt-neg-4 {
  @include px-responsive-margins(
    (
      top: -4
    )
  );
}
.mt-neg-2 {
  @include px-responsive-margins(
    (
      top: -6
    )
  );
}
.mt-neg-1 {
  @include px-responsive-margins(
    (
      top: -8
    )
  );
}

.row.py-half {
  @include px-responsive-paddings(
    (
      top: 3
    )
  );
  > .col,
  > [class*="col-"] {
    @include px-responsive-paddings(
      (
        left: 4,
        right: 4,
        bottom: 3
      )
    );
  }
}

.row {
  @include px-responsive-margins(
    (
      left: -4,
      right: -4
    )
  );
  @include px-responsive-paddings(
    (
      top: 2
    )
  );
  &.with-bottom-padding {
    @include px-responsive-paddings(
      (
        bottom: 2
      )
    );
  }
  > .col,
  > [class*="col-"] {
    @include px-responsive-paddings(
      (
        left: 4,
        right: 4,
        bottom: 2
      )
    );
  }
}

.row.no-gutters {
  @include px-responsive-margins(
    (
      left: 0,
      right: 0
    )
  );
  @include px-responsive-paddings(
    (
      top: 0
    )
  );
  > .col,
  > [class*="col-"] {
    @include px-responsive-paddings(
      (
        left: 0,
        right: 0,
        bottom: 0
      )
    );
  }
}

.text-decoration-underline {
  text-decoration: underline;
}
.text-strike {
  text-decoration: line-through;
}
.rounded-10 {
  border-radius: 10px !important;
}
.rounded-20 {
  border-radius: 20px !important;
}
.fb-border {
  border: 1px solid #eaeaeb !important;
}
.fb-border-left {
  border-left: 1px solid #eaeaeb !important;
}
.fb-border-right {
  border-right: 1px solid #eaeaeb !important;
}
.fb-border-bottom {
  border-bottom: 1px solid #eaeaeb !important;
}
.fb-border-top {
  border-top: 1px solid #eaeaeb !important;
}
.fb-border-radius-5 {
  border-radius: 5px !important;
}
.light-gray-border {
  border: "0.6px solid #d8d8d8";
}
.border-black {
  border-color: #000 !important;
}
.text-black {
  color: #000 !important;
}
.text-grey {
  color: #6F8099 !important;
}
.text-darkBlue{
  color:$darkBlue;
}
.text-light-grey {
  color: #A2AEBF;
}

.text-light-grey-2 {
  color: #70829B;
}
.text-dark-grey {
  color: #37404D !important;
}
.bg-vdark-grey {
  background-color: #70829b !important;
}
.bg-red {
  background-color: red !important;
}
.text-light-white {
  color: #D6D6DB !important;
}

.cursor-notallowed {
  cursor: not-allowed;
}

.copied-top {
  border-top: 2px dashed $primary !important;
}
.copied-bottom {
  border-bottom: 2px dashed $primary !important;
}
.copied-left {
  border-left: 2px dashed $primary !important;
}
.copied-right {
  border-right: 2px dashed $primary !important;
}

.focused {
  border: 2px solid $primary !important;
}
.focused-top {
  border-top: 1px solid $primary !important;
}
.focused-bottom {
  border-bottom: 1px solid $primary !important;
}
.focused-left {
  border-left: 1px solid $primary !important;
}
.focused-right {
  border-right: 1px solid $primary !important;
}
.focused-bg {
  background-color: #e7f0fd !important;
}

.twoLineEllipsis {
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-box {
  // border: 1px solid #FFDEA9;
  padding: 15px;
  border-radius: 6px;
  background-color: #E8F4FD;
}

.info-box-blue {
  background-color: #E8F4FD;
  border-radius: 6px;
}

.height-70 {
  height: 70px !important;
}
.height-2 {
  height: 2px !important;
}
.height-1 {
  height: 1px !important;
}

.text-wrap {
  word-break: break-word !important;
}
.d-grid {
  display: grid;
}
.vis-collapse {
  visibility: collapse;
}

.h-60 {
  height: 60px !important;
}

.font-500 {
  font-weight: 500 !important;
}
.font-700 {
  font-weight: 700 !important;
}


.bg-light-grey {
  background-color: #f5f5f5 !important;
}
.bg-dark-grey {
  background-color: #37404D !important;
}

.bg-black {
  background-color: black !important;
}

.bg-lightPrimary{
  background-color: #317FF51A!important;
}
.bg-darkBlue{
  background-color: $darkBlue !important;
}
.bg-navyBlue{
  background-color: #30344D !important;
}
.bg-login {
  background-image: url(../../images/login-bg.jpg);
}
.bg-info-box {
  background-color: #E8F4FD;
}

.w-20 { width: 20% !important; }
.w-30 { width: 30% !important; }
.w-40 { width: 40% !important; }

.mw-450 {
  max-width: 450px;
}

.white-space-preline {
  white-space: pre-line !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;

  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.dragger {
  display: none;
}
.fb-draw-options div button {
  font-family: inherit;
  background-color: transparent;
  border-color: transparent;
  border-radius: 2px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 14px;
  line-height: 20px;
  height: 36px;
  margin: 0px 5px;
  min-width: 64px;
  opacity: 1;
  outline: none;
  padding: 0px 8px;
  text-transform: uppercase; }

  .fb-draw-options div button:hover {
    background-color: #eeeeee;
  }
  #feedback-js div.fb-form-container form .fb-header {
    background-color: $primary;
    color: "white"
  }
  #feedback-js div.fb-form-container {
    z-index: 1500;
  }
  #feedback-js {
    z-index: 2000
  }
  .no-scroll-bar {
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      height: 0;
      width: 0;
    }
    &::-webkit-scrollbar-track {
      height: 0;
      width: 0;
    }
  }
.absolute-center{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}
.flex-1{
  flex:1;
  min-height:0px;
  min-width: 0px;
}
/*-- Custom Range Slider (Audio) CSS --*/
.audioRange{
  width: 220px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #e1e1e1;
  outline: none;
  height: 3px;
}

.audioRange::-webkit-slider-thumb{
  -webkit-appearance: none;
  background: #000000;
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.audioRange::-moz-slider-thumb{
  -moz-appearance: none;
  background:#000000;
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.avatar-image:before {
  content: " ";
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  pointer-events: none;
  height: 100%;
  border-radius: 50%;
}

.warning-red {
  border: 1px solid #DB5353;
  border-radius: 6px;
  background-color: #FFF7F6;
}

.iti__flag-container {
  padding: 0px !important;
}

.iti--separate-dial-code .iti__selected-flag{
  background-color: white!important;
  border-radius: 4px 0px 0px 4px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide_scroll_bar{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide_scroll_bar::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; 
}

.inset-0 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.inset-top-left     { top: 0;     left: 0; }
.inset-bottom-left  { bottom: 0;  left: 0; }
.inset-top-right    { top: 0;     right: 0; }
.inset-bottom-right { bottom: 0;  right: 0; }
.inset-right        {             right: 0; }

.bg-transluscent-black {
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-transluscent-white {
  background-color: rgba(255, 255, 255, 0.5);
}
.no-select {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}
