.fm-0 {
  margin: 0 !important;
}

.fm-10 {
  margin: 10px !important;
}

.fm-20 {
  margin: 20px !important;
}

.fm-30 {
  margin: 30px !important;
}

.fp-0 {
  padding: 0 !important;
}
.fp-5 {
  padding: 5px !important;
}

.fp-8 {
padding: 8px !important;
}
.fp-10 {
  padding: 10px !important;
}
.fp-15 {
  padding: 15px !important;
}


.fp-20 {
  padding: 20px !important;
}
.fp-25 {
  padding: 25px !important;
}
.fp-30 {
  padding: 30px !important;
}

.fmt-0 {
  margin-top: 0 !important;
}
.fmt-5 {
  margin-top: 5px !important;
}
.fmt-8 {
  margin-top: 8px !important;
}
.fmt-10 {
  margin-top: 10px !important;
}

.fmt-12 {
  margin-top: 12px !important;
}

.fmt-15{
  margin-top: 15px !important;
}
.fmt-20 {
  margin-top: 20px !important;
}
.fmt-25 {
  margin-top: 20px !important;
}

.fmt-30 {
  margin-top: 30px !important;
}
.fmt-40 {
  margin-top: 40px !important;
}

.fmt-71 {
  margin-top: 71px !important;
}

.fpt-0 {
  padding-top: 0 !important;
}
.fpt-5 {
  padding-top: 5px !important;
}
.fpt-6 {
  padding-top: 6px !important;
}
.fpt-8 {
  padding-top: 8px !important;
}
.fpt-10 {
  padding-top: 10px !important;
}
.fpt-15 {
  padding-top: 15px !important;
}
.fpt-20 {
  padding-top: 20px !important;
}
.fpt-25 {
  padding-top: 25px !important;
}

.fpt-30 {
  padding-top: 30px !important;
}

.fpt-35 {
  padding-top: 35px !important;
}

.fpt-45 {
  padding-top: 45px !important;
}

.fpt-50 {
  padding-top: 50px !important;
}

.fml-0 {
  margin-left: 0 !important;
}
.fml-5 {
  margin-left: 5px !important;
}

.fml-8 {
  margin-left: 8px !important;
}

.fml-10 {
  margin-left: 10px !important;
}

.fml-12 {
  margin-left: 12px !important;
}

.fml-15 {
  margin-left: 15px !important;
}

.fml-20 {
  margin-left: 20px !important;
}

.fml-30 {
  margin-left: 30px !important;
}

.fml-40 {
  margin-left: 40px !important;
}
.fml-44 {
  margin-left: 44px !important;
}

.fml-44 {
  margin-left: 44px !important;
}

.fml-78 {
  margin-left: 78px !important;
}

.fpl-0 {
  padding-left: 0 !important;
}
.fpl-8 {
  padding-left: 8px !important;
}

.fpl-10 {
  padding-left: 10px !important;
}
.fpl-12 {
  padding-left: 12px !important;
}

.fpl-20 {
  padding-left: 20px !important;
}

.fpl-30 {
  padding-left: 30px !important;
}

.fpl-35 {
  padding-left: 35px !important;
}

.fpl-45 {
  padding-left: 45px !important;
}

.fmr-0 {
  margin-right: 0 !important;
}

.fmr-4 {
  margin-right: 4px !important;
}

.fmr-5 {
  margin-right: 5px !important;
}

.fmr-8 {
  margin-right: 8px !important;
}

.fmr-10 {
  margin-right: 10px !important;
}

.fmr-14 {
  margin-right: 14px !important;
}

.fmr-12 {
  margin-right: 12px !important;
}

.fmr-15 {
  margin-right: 15px !important;
}
.fmr-20 {
  margin-right: 20px !important;
}
.fmr-25 {
  margin-right: 25px !important;
}
.fmr-30 {
  margin-right: 30px !important;
}
.fmr-50{
  margin-right: 50px !important;
}
.fmr-40{
  margin-right: 40px !important;
}
.fmr-75 {
  margin-right: 75px !important;
}
.fmr-100 {
  margin-right: 100px !important;
}

.fpr-0 {
  padding-right: 0 !important;
}

.fpr-6 {
  padding-right: 6px !important;
}

.fpr-10 {
  padding-right: 10px !important;
}

.fpr-12 {
  padding-right: 12px !important;
}

.fpr-15 {
  padding-right: 15px !important;
}

.fpr-20 {
  padding-right: 20px !important;
}

.fpr-25 {
  padding-right: 25px !important;
}

.fpr-30 {
  padding-right: 30px !important;
}
.fpr-40 {
  padding-right: 40px !important;
}
.fmb-0 {
  margin-bottom: 0 !important;
}

.fmb-5 {
  margin-bottom: 5px !important;
}
.fmb-8 {
  margin-bottom: 8px !important;
}

.fmb-10 {
  margin-bottom: 10px !important;
}
.fmb-12 {
  margin-bottom: 12px !important;
}
.fmb-15 {
  margin-bottom: 15px !important;
}
.fmb-20 {
  margin-bottom: 20px !important;
}
.fmb-25 {
  margin-bottom: 25px !important;
}
.fmb-30 {
  margin-bottom: 30px !important;
}
.fmb-40 {
  margin-bottom: 40px !important;
}
.fmbn-10{
  margin-bottom: -10px !important;
}

.fpb-0 {
  padding-bottom: 0 !important;
}
.fpb-5 {
  padding-bottom: 5px !important;
}
.fpb-8 {
  padding-bottom: 8px !important;
}
.fpb-10 {
  padding-bottom: 10px !important;
}

.fpb-15 {
  padding-bottom: 15px !important;
}
.fpb-20 {
  padding-bottom: 20px !important;
}

.fpb-25 {
  padding-bottom: 25px !important;
}

.fpb-30 {
  padding-bottom: 30px !important;
}

.fpb-35 {
  padding-bottom: 35px !important;
}

.fmy-0 {
  @extend .fmt-0;
  @extend .fmb-0;
}

.fmy-10 {
  @extend .fmt-10;
  @extend .fmb-10;
}

.fmy-20 {
  @extend .fmt-20;
  @extend .fmb-20;
}

.fmy-30 {
  @extend .fmt-30;
  @extend .fmb-30;
}

.fmy-40 {
  @extend .fmt-40;
  @extend .fmb-40;
}

.fpy-0 {
  @extend .fpt-0;
  @extend .fpb-0;
}
.fpy-5 {
  @extend .fpt-5;
  @extend .fpb-5;
}

.fpy-8 {
  @extend .fpt-8;
  @extend .fpb-8;
}

.fpy-10 {
  @extend .fpt-10;
  @extend .fpb-10;
}


.fpy-15 {
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}

.fpy-20 {
  @extend .fpt-20;
  @extend .fpb-20;
}
.fpy-25 {
  @extend .fpt-25;
  padding-bottom: 25px !important;
}

.fpy-30 {
  @extend .fpt-30;
  @extend .fpb-30;
}
.fpy-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.fpy-45 {
  padding-bottom: 45px !important;
  padding-top: 45px !important;
}

.fmx-0 {
  @extend .fml-0;
  @extend .fmr-0;
}

.fmx-10 {
  @extend .fml-10;
  @extend .fmr-10;
}

.fmx-20 {
  @extend .fml-20;
  @extend .fmr-20;
}

.fmx-30 {
  @extend .fml-30;
  @extend .fmr-30;
}

.fpx-0 {
  @extend .fpl-0;
  @extend .fpr-0;
}

.fpx-10 {
  @extend .fpr-10;
  @extend .fpl-10;
}

.fpx-12 {
  @extend .fpr-12;
  @extend .fpl-12;
}

.fpx-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.fpx-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.fpx-20 {
  @extend .fpl-20;
  @extend .fpr-20;
}
.fpx-25 {
  @extend .fpl-25;
  @extend .fpr-25;
}

.fpx-30 {
  @extend .fpr-30;
  @extend .fpl-30;
}

.fpx-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.fpx-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.fpl-40 {
  padding-left: 40px !important;
}

.fpr-40 {
  padding-right: 40px !important;
}

.fpl-5 {
  padding-left: 5px !important;
}

.fpl-25 {
  padding-left: 25px !important;
}

.fmr-n10 {
  margin-right: -10px !important;
}

.fmln-10 {
  margin-left: -10px !important;
}
.fmln-12 {
  margin-left: -12px !important;
}
.fmln-20 {
  margin-left: -20px !important;
}
.fmrn-20 {
  margin-right: -20px !important;
}
.fmt-n30 {
  margin-top: -30px !important;
}
.fml-n40 {
  margin-left: -40px !important;
}
.fmt-n40 {
  margin-top: -40px !important;
}

.fmln-5 {
  margin-left: -5px !important;
}
.fmtn-5 {
  margin-top: -5px !important;
}

.fmtn-10 {
  margin-top: -10px !important;
}

.fmtn-20 {
  margin-top: -20px !important;
}

.fmln-10 {
  margin-left: -10px !important;
}

.fmrn-10 {
  margin-right: -10px !important;
}

.fmx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.fmt-25 {
  margin-top: 25px !important;
}

.fmy-3px {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.offr-10 {
  right: 10px;
}
.flexw-1{
  flex:1;
  min-width:0px;
}
